/* eslint-disable react/display-name */
const React = require('react')
const ThemeProvider = require('./src/Context/ThemeContext').themeProvider

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {
  return <ThemeProvider {...props}>{element}</ThemeProvider>
}

exports.onServiceWorkerUpdateFound = () => {
  window.location.reload()
}
