import React from 'react'

import { ThemeProvider } from '@material-ui/styles'
import useLocalStorage from '../hooks/useLocalStorage'
import { darkTheme } from '../theme/dark'
import { responsiveFontSizes } from '@material-ui/core'

const defaultState = {
  theme: 'dark',
  toggleLightMode: () => {},
}

export const themeContext = React.createContext(defaultState)

export const themeProvider = (props) => {
  const [theme, setTheme] = useLocalStorage(undefined)
  const toggleLightMode = () => {
    setTheme(!theme)
  }

  return (
    <ThemeProvider
      theme={
        // theme ? responsiveFontSizes(lightTheme) : responsiveFontSizes(darkTheme)
        responsiveFontSizes(darkTheme)
      }
    >
      <themeContext.Provider
        value={{
          theme,
          toggleLightMode,
        }}
      >
        {props.children}
      </themeContext.Provider>
    </ThemeProvider>
  )
}
